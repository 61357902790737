var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "themelogout",
      style: { background: _vm.$store.getters.getColor("bgColorSecundary") },
    },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "pt-2",
          attrs: { flat: "", color: "transparent", dark: "" },
        },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular text-capitalize pl-0",
                  attrs: { text: "", exact: "" },
                  on: { click: _vm.voltar },
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-chevron-left"),
                  ]),
                  _c("span", [_vm._v("voltar")]),
                ],
                1
              ),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mb-10 d-none d-md-block",
          staticStyle: { position: "relative", "z-index": "1" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column justify-center align-center",
              attrs: { cols: "12" },
            },
            [
              _vm.$store.getters.getLogoClaro
                ? _c("img", {
                    staticClass: "mt-2 mb-4",
                    attrs: {
                      src: _vm.$store.getters.getLogoClaro,
                      width: "110",
                    },
                  })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-slide-x-reverse-transition",
        { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
        [_c("verificacao")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }