<template>
  <v-container class="pa-5 pt-0 mt-5">
    <v-row no-gutters class="mb-0">
      <v-col>
        <h1 class="white--text title font-weight-bold">Enviamos um código de verificação</h1>
        <validation-observer ref="form">
          <v-form class="mt-4" @submit.prevent="submit()">
            <validation-provider v-slot="{errors}" name="código" vid="codigo" rules="required|min:4">
              <v-text-field
                maxlength="4"
                class="text-uppercase"
                v-model="form.codigo"
                :error-messages="errors"
                name="codigo"
                label="Informe o código"
                required
              ></v-text-field>
            </validation-provider>
            <div  class="d-flex justify-end text-capitalize d-block font-weight-bold text--white">
              <div v-if="dataContadorState === 0" class="white--text d-flex text--white cursor-pointer" @click="enviarToken">
                <div class=" mr-1 text--white">Reenviar código</div>
                <div class="text-capitalize d-block font-weight-bold contador"></div>
              </div>
              <div v-else class="white--text d-flex text--white">
                <div class=" mr-1 text--white">Reenviar código</div>
                <div class="text-capitalize d-block font-weight-bold contador">({{ dataContadorState }})</div>
              </div>
            </div>
            <v-btn
              block
              large
              type="submit"
              elevation="10"
              color="white"
              class="text-none mb-4 mt-5 font-weight-bold"
              :loading="loading"
            >
              <b class="primary--text">Verificar</b>
                <span slot="loader">
                <v-progress-circular
                  indeterminate
                  :width="3"
                  color="primary"
                ></v-progress-circular>
              </span>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import usuarioService from '@/services/usuarioService';
import { mapActions, mapState } from 'vuex';
import { Capacitor } from '@capacitor/core'
import { isEmpty } from "lodash";
import { SecureStorage } from '@aparajita/capacitor-secure-storage'

export default {
  name: 'Verificacao',
  data() {
    return {
      loading: false,
      cpf: null,
      password: null,
      email: null,
      emailDestinatario: null,
      form: {
        codigo: '',
      },
    };
  },
  computed: {
    ...mapState({
      dataUserState: (state) => state.cadastroCorretor.corretor,
      dataContadorState: (state) => state.cadastroCorretor.contador,
    }),
    dispositivoMovel: () => Capacitor.isNativePlatform(),
  },
  async created() {
    this.cpf = sessionStorage.getItem("corretorcpf")
    this.email = sessionStorage.getItem("corretoremail")
    this.emailDestinatario = sessionStorage.getItem("corretoremaildestinatario")
    this.password = sessionStorage.getItem("corretorpw")
    if(Capacitor.isNativePlatform()) {
      this.verficaCredenciais();
    }
    await this.enviarToken();
  },
  watch: {},
  methods: {
    ...mapActions({
      setContador: 'cadastroCorretor/setContador',
      login: "auth/login"
    }),
    async submitLogin() {
      this.$store.commit("SET_CALLBACK_TERMO", '');

      this.$store.commit("SET_LOADING_GLOBAL", true);
      try {
        const { cpf } = await this.login({username: this.email, password: this.password});
        this.$store.commit("SET_LOADING_GLOBAL", false);

        const dataUser = await this.carregaDadosCorretor(cpf);
        if (isEmpty(dataUser)) throw new Error("Erro ao caregar dados do corretor");

        const { flagConfirmacaoDados, tipoCorretor, subTipoCorretor } = dataUser;

        if(Capacitor.isNativePlatform()) {
          this.setCredenciais({username: this.email, password: this.password});
        }

        if (!flagConfirmacaoDados && tipoCorretor !== "Interno" && subTipoCorretor !== "PME" && subTipoCorretor !== "Atendimento") {
          // Altera tentativa de confirmação de dados
          await usuarioService.addTentativaConfirmacaoDados({ cpf });
          this.$router.push({ name: "areaLogada.confirmacaoDados", query: { login: true } });
        } else if (this.$route.query && this.$route.query.path) this.$router.push(this.$route.query);
        else this.$router.push("/");
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$store.commit("SET_LOADING_GLOBAL", false);
      }
    },
    async verficaCredenciais() {
      await this.setKeyPrefix();
      const data = await SecureStorage.get(this.keyPrefix);
      this.temCredenciais = data ? true : false;
      if(this.temCredenciais) {
        this.form.username = data.username;
      }
    },
    async carregaDadosCorretor(cpf) {
      try {
        const dataUser = await usuarioService.buscarCorretor({ cpf });
        this.$store.dispatch("user/setUserData", dataUser, { root: true });
        const { data: urlFotoPerfil } = await usuarioService.getFotoPerfil();
        this.$store.dispatch("user/setUrlFotoPerfil", urlFotoPerfil);
        return dataUser;
      } catch (error) {
        localStorage.removeItem(`${process.env.VUE_APP_NAME_AUTH}`);
        return {};
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      try {
        this.loading = true;
        await usuarioService.validarToken({ cpf: this.cpf, token: this.form.codigo });
        this.submitLogin()
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.loading = false;
      }
    },
    async enviarToken() {
      try {
        const corretorsms = sessionStorage.getItem("corretorsms")
        if(this.dataContadorState === 0) this.setContador(40)
        await usuarioService.enviarToken({ 
          cpf: this.cpf,
          payload: {
            tipoToken: "ENVIOMFA",
            destinatarioEmail: this.emailDestinatario,
            destinatarioSMS: corretorsms
          }
        });
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
      }
    },
    async setKeyPrefix() {
      this.keyPrefix = 'capacitor-storage_qualivendas_';
      await SecureStorage.setKeyPrefix(this.keyPrefix);
    },
  },
};
</script>
<style scoped>
.contador {
  width: 25px
}
</style>
