<template>
  <div class="themelogout" :style="{background: $store.getters.getColor('bgColorSecundary')}">
    <v-app-bar flat color="transparent" class="pt-2" dark>
      <v-container class="d-flex align-center pa-0">
        <v-btn text class="font-weight-regular text-capitalize pl-0" exact @click="voltar" >
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          <span>voltar</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-container>
    </v-app-bar>
     <v-row no-gutters class="mb-10 d-none d-md-block" style="position: relative; z-index: 1">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <img v-if="$store.getters.getLogoClaro" :src="$store.getters.getLogoClaro" width="110" class="mt-2 mb-4" />
          <!-- <h1 class="white--text">Venda digital</h1> -->
        </v-col>
      </v-row>
    <v-slide-x-reverse-transition leave-absolute hide-on-leave>
      <verificacao />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
import PreventRealodOnEditing from '@/mixins/preventReloadOnEditing';
import Verificacao from './Verificacao.vue';

export default {
  name: 'IndexCadastro',
  mixins: [PreventRealodOnEditing],
  components: {Verificacao},
  data() {
    return {}
  },
  methods: {
    voltar() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>
